// Packages
import React from "react";
import { graphql } from "gatsby";
import type { IGatsbyImageData } from "gatsby-plugin-image";
import { BeakerIcon, CurrencyEuroIcon, UsersIcon, FastForwardIcon } from "@heroicons/react/outline";

// Types
import type { SiteMetadata, Location } from "~types/Gatsby";

// Components
import P from "~components/typography/P";
import H2 from "~components/typography/H2";
import Breadcrumb from "~components/common/Bradcrumb";
import Hero from "~components/sections/Hero";
import Ul from "~components/typography/Ul";
import H1 from "~components/typography/H1";
import Container from "~components/common/Container";
import Grid from "~components/common/Grid";
import FeatureIcon from "~components/features/FeatureIcon";
import Layout from "~components/layouts/Layout";
import SubTitle from "~components/typography/SubTitle";
import Testmonials from "~components/sections/Testmonials";
import { testmonials } from "~data/testmonials";
import Button from "~components/common/Button";

interface DataProps {
	site: {
		siteMetadata: SiteMetadata;
	};
	header: IGatsbyImageData;
}

export default function Page({ data, location }: { data: DataProps; location: Location }) {
	return (
		<Layout
			location={location}
			title="Firmen Speed Reading Workshops"
			desc=""
			// imageFacebook={`${data.site.siteMetadata.siteUrl}${data.seoImage.facebook.gatsbyImageData.images.fallback.src}`}
			// imageFacebookWidth={data.seoImage.facebook.gatsbyImageData.width}
			// imageFacebookHeight={data.seoImage.facebook.gatsbyImageData.height}
			// imageTwitter={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitter.gatsbyImageData.images.fallback.src}`}
		>
			<Breadcrumb
				pages={[
					{
						name: "Workshops",
						to: "/workshops/",
					},
					{
						name: "Firmen Speed Reading",
						to: "/workshops/firmen-speed-reading/",
						current: true,
					},
				]}
			/>
			<Hero image={data.header} align="start">
				<SubTitle className="mb-2">Workshop</SubTitle>
				<H1 display className="mb-4">
					Speed Reading Trainings für Unternehmen
				</H1>
				<H2 className="mb-4">
					Leaders are Readers: Maximale Effizienz durch verbesserte Lesegeschwindigkeit und Informationsverarbeitung
				</H2>
				<P className="mb-4">
					Steigere die Produktivität deines Unternehmens durch unsere exklusiven Speed Reading-Trainings. Diese Kurse
					sind speziell darauf ausgerichtet, Mitarbeitern und Führungskräften zu ermöglichen, ihre Lesegeschwindigkeit
					signifikant zu erhöhen und ihr Textverständnis zu verbessern. In einer schnelllebigen Geschäftswelt, in der
					die Informationsflut stetig wächst, sind solche Fähigkeiten unverzichtbar.
				</P>
				<P className="mb-4 font-bold">Training Highlights:</P>
				<Ul
					items={[
						<>
							<b>Exklusive Speed Reading-Trainings:</b>
							<br />
							Wir bieten spezialisierte Trainingsmodule an, die auf die Bedürfnisse deiner Mitarbeiter zugeschnitten
							sind. Unsere Experten vermitteln Techniken, die nicht nur die Lesegeschwindigkeit erhöhen, sondern auch
							das Verständnis und die Informationsverarbeitung verbessern.
						</>,
						<>
							<b>Fokus auf Effizienz:</b>
							<br />
							Unsere Trainings konzentrieren sich darauf, die Informationsaufnahme zu beschleunigen und die
							Verarbeitungseffizienz zu steigern. Dies führt zu einer deutlichen Produktivitätssteigerung in deinem
							Unternehmen.
						</>,
						<>
							<b>Für die Ära der Künstlichen Intelligenz:</b>
							<br />
							In Zeiten von KI und umfangreichen Datenmengen ist es entscheidend, dass deine Mitarbeiter Informationen
							schnell und effektiv verarbeiten können, um wettbewerbsfähig zu bleiben.
						</>,
					]}
					className="mb-10"
					pretty
				></Ul>
				<P className="mb-4 font-bold">Vorteile für dein Unternehmen:</P>
				<Ul
					items={[
						<>
							<b>Produktivitätssteigerung:</b>
							<br />
							Mitarbeiter, die effizienter lesen und Informationen verarbeiten, können Aufgaben schneller abschließen
							und haben mehr Zeit für komplexe Problemlösungen.
						</>,
						<>
							<b>Anpassbare Trainingslösungen:</b>
							<br />
							Wir bieten flexible Trainingsmodule an, die auf die spezifischen Anforderungen und Ziele deines
							Unternehmens abgestimmt sind.
						</>,
					]}
					className="mb-10"
					pretty
				></Ul>
				<P>
					Bereit, die Produktivität deines Teams zu steigern? Kontaktiere uns für mehr Informationen oder um ein
					maßgeschneidertes Speed Reading-Training für dein Unternehmen zu planen. Nutze unser Kontaktformular oder rufe
					uns direkt an.
				</P>

				<Button to="/workshops/firmen-speed-reading/anfragen/" text="Jetzt anfragen" />
			</Hero>

			<Testmonials data={testmonials} />

			<Container>
				<div>
					<SubTitle className="mb-2">Beschreibung</SubTitle>
					<H2 display className="mb-4">
						Deine Vorteile
					</H2>
				</div>
				<Grid cols={2}>
					<div>
						<P className="mb-3">
							Für alle, die ihre mentale Leistungs- und Konzentrationsfähigkeit verbessern möchten oder im Alltag mit zu
							vielen Informationen kämpfen. Und natürlich für alle, die mehr Informationen und Wissen in kürzester Zeit
							aufnehmen und abspeichern möchten. Alle Themen sind auf eine einfache Integration in den Alltag ausgelegt
							und ich trainiere häufig Akademiker und High-Performer, die anspruchsvolle Berufe ausüben. Bei jeder Form
							von Gehirntraining ist die Regelmäßigkeit wichtiger als die Intensität.
						</P>
						<P className="mb-3">
							Ob E-Mails, Memos, Dokumente, Zeitschriften oder Fachliteratur: Schreibtisch-Arbeitende lesen etwa 3
							Stunden pro Arbeitstag. Das sind etwa 660 Stunden im Jahr oder 82,5 volle 8-Stunden-Arbeitstage. Stell Dir
							vor:
						</P>
						<Ul
							items={[
								"Stell dir vor, wie sich Dein Leben verändern würde, wenn Du Deine Lesegeschwindigkeit dauerhaft um nur 10% steigern könntest. Du kannst jeden Tag eine Viertelstunde länger Pause machen, früher nach Hause gehen oder mehr lernen und erreichen.",
								"Jetzt überlege Dir, wie das bei 30% Steigerung wäre: Du könntest 25 komplette Arbeitstage pro Jahr einsparen! Deine Produktivität würde in ungeahnte Höhen schießen und Du hätest einen riesigen Wettbewerbsvorteil.",
							]}
							className="mb-3"
						/>
						<P>
							Mit unseren Trainings sind sogar Steigerungen von 50-70% möglich, und das sogar bei komplexen Fachtexten!
							Bei leichten Texten wirst Du Dein Tempo locker verdoppeln oder gar verdreifachen. Rechne selbst nach, wie
							hoch Dein ROI (Return on Investment) sein wird, wenn Du ab sofort jedes Jahr mehrere hundert Stunden
							Arbeitszeit einsparst und vielfach mehr erreichst. Und was würdest Du mit dieser freigewonnenen Zeit
							anstellen? Mehr arbeiten und mehr Geld verdienen? Weniger arbeiten und mehr Zeit mit Freunden und Familie
							verbringen? Mehr Pausen einbauen und entspannter arbeiten? Erfolgreicher Studieren oder sich weiterbilden
							für mehr Erfolg? Was es auch sein mag, in jedem Fall bleibt dir mehr Zeit zum gemütlichen Genusslesen.
						</P>
					</div>

					<Grid cols={1}>
						{[
							{
								icon: FastForwardIcon,
								title: "Schneller lesen",
								description:
									"Einfache Texte rund 100% und Fachtexte ca. 75% schneller in der gleichen oder der Hälfte der Zeit.",
								color: "blue" as const,
							},
							{
								icon: CurrencyEuroIcon,
								title: "Geld-zurück-Garantie",
								description:
									"Solltest Du mit den Ergebnissen, dem Trainer oder dem Kurs-Format nicht zufrieden sein, zahlen wir Dir Dein Geld einfach zurück*.",
								color: "blue" as const,
							},
							{
								icon: BeakerIcon,
								title: "Nach aktuellen Leseforschungen",
								description:
									"Du übst in einem Raum mit dem Trainer und anderen angehenden Speedreadern und erlebst gemeinsam die anfänglichen Zweifel, dann die Fortschritte und schließlich die Begeisterung.",
								color: "blue" as const,
							},
							{
								icon: UsersIcon,
								title: "Netzwerken",
								description:
									"Verbringe 2 Tage Zeit mit weiteren spannenden Persönlichkeiten. In unseren Seminaren sind schon so manche Freundschaften und auch Geschäftsverbindungen entstanden.",
								color: "blue" as const,
							},
						].map((feature) => {
							return (
								<FeatureIcon
									title={feature.title}
									description={feature.description}
									icon={feature.icon}
									color={feature.color}
									align="left"
									key={feature.title}
								/>
							);
						})}
					</Grid>
				</Grid>
			</Container>
		</Layout>
	);
}

export const query = graphql`
	{
		site {
			siteMetadata {
				siteUrl
			}
		}
		header: file(relativePath: { eq: "pages/workshops/speed-reading/speed-reading_daniel-thiel_header.jpg" }) {
			childImageSharp {
				gatsbyImageData
			}
		}
	}
`;
